<template>
  <main>
    <div class="layout-subcontainer">
      <div class="box-wrap d-flex">
        <div class="col-6 pa-0">
          <h1 class="tit-h1 d-flex">알림톡관리
            <div class="ml-auto">
              <v-btn outlined small class="btn-etc2 ml-2" @click="getListData" id="btnSearch">조회</v-btn>
            </div>
          </h1>
          <div class="box-ct mt-2">
            <div class="table-form d-flex">
              <table>
                <caption class="hide">
                  사용자정보 항목
                </caption>
                <colgroup>
                  <col width="148px" />
                  <col width="240px" />
                  <col width="148px" />
                  <col width="" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <!-- <v-badge dot color="#EF9191"> -->
                        건설사명
                      <!-- </v-badge> -->
                    </th>
                    <td >
                      <div class="form-inp full">
                        <v-autocomplete
                          :items="ctcpTypeCd"
                          item-text="CD_NM"
                          item-value="CD"
                          outlined
                          @change="changeDetailBussType(CTCP_CODE)"
                          v-model="CTCP_CODE"
                          hide-details
                          placeholder="선택하세요"
                        >
                        </v-autocomplete>
                      </div>
                    </td>
                    <th scope="row">
                      <!-- <v-badge dot color="#EF9191"> -->
                        사업지명
                      <!-- </v-badge> -->
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-autocomplete
                          :items="bussTypeCd"
                          item-text="CD_NM"
                          item-value="CD"
                          outlined
                          v-model="BUSS_CODE"
                          :disabled="bussTypeCd.length == 0 ? true : false"
                          placeholder="선택하세요"
                        >
                        </v-autocomplete>
                      </div>
                    </td>
                  </tr> 
                  <tr>
                    <th scope="row" >
                      <!-- <v-badge dot color="#EF9191"> -->
                        템플릿명
                      <!-- </v-badge> -->
                    </th>
                    <td >
                      <div class="form-inp full" >
                        <v-text-field
                          style="margin-left: -0.5px !important;"
                          class="form-inp ml-2"
                          label="템플릿명"
                          outlined
                          hide-details
                          v-model="S_TMPL_NAME"
                          v-on:keyup.enter="getListData" 
                        >
                        </v-text-field>
                      </div>
                    </td>
                  </tr>
                  
                </tbody>
              </table>
            </div>
          </div>
          <div class="box-ct mt-3">
            <h1 class="tit-h1 d-flex">알림톡 목록

            </h1>
            <div class="mt-2">
              <v-data-table
                dense
                height="590px"
                :headers="gridDataHeaders"
                :items="gridDataText"
                :items-per-page="itemsPerPage"
                item-key="ROW_NUMBER"
                :page.sync="page"
                hide-default-footer
                class="grid-default"
                fixed-header
                v-model="selGroupCd"
                @page-count="pageCount = $event"
                @click:row="gridRowClick"
                :item-class="clickActive"
                no-data-text="검색된 결과가 없습니다."
              ></v-data-table>
              <div class="grid-paging text-center pt-2">
                <span class="grid-total-count">총 <strong>{{ gridDataText.length }}</strong>건</span>
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  :total-visible="totalVisible"
                  next-icon="svg-paging-next"
                  prev-icon="svg-paging-prev"
                ></v-pagination>
              </div>
            </div>
          </div>

        </div>
        <!-- 알림톡 상세내용 -->
        <div class="ml-6 flex-grow-1">
          <h1 class="tit-h1 d-flex">알림톡 상세내용
            <div class="ml-auto">
              <v-btn
              outlined
              class="btn-default ml-2"
              @click="copyBtnForm()"
              >복사</v-btn
              >
              <v-btn
                  outlined
                  class="btn-default ml-2"
                  @click="newBtnForm()"
              >신규</v-btn
              >
              <v-btn
                  outlined
                  class="btn-etc ml-2"
                  @click="clickBtnForm('DELETE')"
              >삭제</v-btn
              >
              <v-btn
                  outlined
                  type = "button"
                  class="btn-point ml-2"
                  @click="clickBtnForm('UPDATE')"
              >저장</v-btn
              >
            </div>
          </h1>
          <div class="box-ct mt-2">
            <div class="table-form d-flex">
              <table>
                <caption class="hide">
                  사용자정보 항목
                </caption>
                <colgroup>
                  <col width="148px" />
                  <col width="300px" />
                  <col width="148px" />
                  <col width="" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <v-badge dot color="#EF9191">건설사명</v-badge>
                    </th>
                    <td >
                      <div class="form-inp full">
                        <v-autocomplete
                          class="form-inp full"
                          :items="ctcpTypeCdT"
                          item-text="CD_NM"
                          item-value="CD"
                          outlined
                          hide-details
                          required
                          @change="changeDetailTxtBussType(T_CTCP_CODE)"
                          v-model="T_CTCP_CODE"
                          :disabled="dis_ctcpCode"
                        >
                        </v-autocomplete>
                      </div>
                    </td>
                    <th scope="row">
                      <v-badge dot color="#EF9191">사업지명</v-badge>
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-autocomplete
                          class="form-inp full"
                          :items="bussTypeCdT"
                          item-text="CD_NM"
                          item-value="CD"
                          outlined
                          hide-details
                          required
                          v-model="T_BUSS_CODE"
                          :disabled="dis_BussCode"
                        >
                        </v-autocomplete>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      채널 ID
                    </th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        :disabled="dis_plusId"
                        v-model="T_PLUS_ID"
                      >
                      </v-text-field>
                    </td>
                    <th scope="row">
                      <v-badge dot color="#EF9191">
                        TPL코드
                      </v-badge>
                    </th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        :disabled="dis_msgTmplCode"
                        v-model="T_MSG_TMPL_CODE"  
                      >
                      </v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">발송키값</th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        :disabled="dis_sndrKey"
                        v-model="T_SNDR_KEY"
                      >
                      </v-text-field>
                    </td>
                    <th scope="row">발송전화번호</th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        :disabled="dis_sndrPhone"
                        v-model="T_SNDR_PHONE"
                      >
                      </v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <v-badge dot color="#EF9191">유형</v-badge>
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-select
                          class="form-inp full"
                          :items="mixin_common_code_get(this.allCodeList,'HLW_MSG_KIND_CD')"
                          item-text="CD_NM"
                          item-value="CD"
                          outlined
                          hide-details
                          required
                          v-model="T_MSG_KIND_CODE"
                          :disabled="dis_msgKindCode"
                        >
                        </v-select>
                      </div>
                    </td>
                    <th scope="row">템플릿유형</th>
                    <td>
                      <div class="form-inp full">
                        <v-select
                          class="form-inp full"
                          :items="mixin_common_code_get(this.allCodeList,'HLW_MSG_TYPE_CD')"
                          item-text="CD_NM"
                          item-value="CD"
                          outlined
                          hide-details
                          required
                          v-model="T_MSG_TYPE_CODE"
                          :disabled="dis_msgTypeCode"
                        >
                        </v-select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <v-badge dot color="#EF9191">
                        템플릿명
                      </v-badge>
                    </th>
                    <td colspan="3">
                      <v-text-field
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        :disabled="dis_msgTmplName"
                        v-model="T_MSG_TMPL_NAME"
                      >
                      </v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      템플릿내용
                    </th>
                    <td colspan="3">
                      <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        :disabled="dis_msgCont"
                        v-model="T_MSG_CONT"
                        height="150px"
                      ></v-textarea>
                    </td>
                  </tr>
                  <tr  :hidden="this.T_MSG_TYPE_CODE!='MSG_TYPE_CD_03' && this.T_MSG_TYPE_CODE!='MSG_TYPE_CD_04' "    >
                    <th scope="row">
                      광고성 메세지
                    </th>
                    <td colspan="3">
                      <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        :disabled="dis_msgCont"
                        v-model="T_ADTM_CONT"
                        height="150px"
                      ></v-textarea>
                    </td>
                  </tr>
                  <tr :hidden="this.T_MSG_TYPE_CODE!='MSG_TYPE_CD_02' && this.T_MSG_TYPE_CODE!='MSG_TYPE_CD_04'">
                    <th scope="row">
                      부가정보
                    </th>
                    <td colspan="3">
                      <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        :disabled="dis_msgCont"
                        v-model="T_ADD_CONT"
                        height="150px"
                      ></v-textarea>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">메세지강조유형</th>
                    <td>
                      <div class="form-inp full">
                        <v-select
                          class="form-inp full"
                          :items="mixin_common_code_get(this.allCodeList,'HLW_EPHS_TYPE_CD')"
                          item-text="CD_NM"
                          item-value="CD"
                          outlined
                          hide-details
                          required
                          v-model="T_EPHS_TYPE_CD"
                          :disabled="dis_msgAttr"
                        >
                        </v-select>
                      </div>
                    </td>
                    <th scope="row">상태</th>
                    <td>
                      <div class="form-inp full">
                        <v-select
                          class="form-inp full"
                          :items="mixin_common_code_get(this.allCodeList,'HLW_USE_YN')"
                          item-text="CD_NM"
                          item-value="CD"
                          outlined
                          hide-details
                          required
                          v-model="T_USE_YN"
                          :disabled="dis_useYn"
                        >
                        </v-select>
                      </div>
                    </td>
                  </tr>
                  <tr  :hidden="this.T_EPHS_TYPE_CD!='EPHS_TYPE_CD_02'"  >
                    <th scope="row">
                      강조 표기 타이틀
                    </th>
                    <td colspan="3">
                      <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        maxlength="50"
                        :disabled="dis_msgAttr"
                        v-model="T_EPHS_TITLE"
                      ></v-textarea>
                    </td>
                  </tr>
                  <tr  :hidden="this.T_EPHS_TYPE_CD!='EPHS_TYPE_CD_04'"  >
                    <th scope="row">
                      헤더
                    </th>
                    <td colspan="3">
                      <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        :disabled="dis_msgAttr"
                        v-model="T_EPHS_ITEM_HEAD"
                      ></v-textarea>
                    </td>
                  </tr>
                  <tr  :hidden="this.T_EPHS_TYPE_CD!='EPHS_TYPE_CD_04'"  >
                    <th scope="row">
                      하이라이트 타이틀
                    </th>
                    <td colspan="3">
                      <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        :disabled="dis_msgAttr"
                        v-model="T_EPHS_ITEM_TITLE"
                      ></v-textarea>
                    </td>
                  </tr>
                  <tr  :hidden="this.T_EPHS_TYPE_CD!='EPHS_TYPE_CD_04'"  >
                    <th scope="row">
                      디스크립션
                    </th>
                    <td colspan="3">
                      <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        :disabled="dis_msgAttr"
                        v-model="T_EPHS_ITEM_DESCRIPTION"
                      ></v-textarea>
                    </td>
                  </tr>
                  <tr  :hidden="this.T_EPHS_TYPE_CD!='EPHS_TYPE_CD_04'"  >
                    <th scope="row">
                      아이템 명
                    </th>
                    <td colspan="3">
                      <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        :disabled="dis_msgAttr"
                        v-model="T_EPHS_ITEM_NAME"
                      ></v-textarea>
                    </td>
                  </tr>
                  <tr  :hidden="this.T_EPHS_TYPE_CD!='EPHS_TYPE_CD_04'"  >
                    <th scope="row">
                      아이템 내용
                    </th>
                    <td colspan="3">
                      <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        :disabled="dis_msgAttr"
                        v-model="T_EPHS_ITEM_DESC"
                      ></v-textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="box-ct mt-3">
            <h1 class="tit-h1 d-flex">알림톡 버튼목록
              <div class="d-flex ml-auto">
                <v-btn outlined small class="btn-default" @click="registBtn">신규</v-btn>
                <v-btn outlined small class="btn-etc ml-2" @click="showDeleteBtnConfirm">삭제</v-btn>
              </div>
              <v-dialog v-if="dialogCAS0200" max-width="655" persistent v-model="dialogCAS0200" content-class="square-modal min-auto">
                <dialog-CAS0200
                  :param="CAS0200Param"
                  p01
                  @hide="hideDialog('CAS0200')"
                  @hideAndGetList="hideAndGetList('CAS0200')"
                  @select="getListBtnData"
                >
                </dialog-CAS0200>
              </v-dialog>
            </h1>
            <div class="mt-2">
              <v-data-table
                dense
                :headers="gridBtnHeaders"
                :items="gridBtnList"
                hide-default-footer
                class="grid-default"
                height="271px"
                item-key="ROW_INDEX"
                no-data-text="검색된 결과가 없습니다."
                v-model="selBtnGroupCd"
                :item-class="clickBtnActive"
                @dblclick:row="setGirdBtnDblSelected"
                @click:row="gridRowBtnClick"
              ></v-data-table>
            </div>
          </div>

        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import DialogCAS0200 from "./CAS0200.vue";

  export default {
    name: "MENU_CAM0300", //name은 'MENU_' + 파일명 조합
    components: {
      DialogCAS0200,
    },
    data() {
      return {
        dialogCAS0200: false,
        CAS0200Param: {},
        selBtnGroupCd : [],
        selBtnGroup : {},
        dis_ctcpCode: true,
        dis_BussCode: true,
        dis_plusId: true,
        dis_msgTmplName: true,
        dis_sndrKey: true,
        dis_sndrPhone: true,
        dis_msgKindCode: true,
        dis_msgTypeCode: true,
        dis_msgTmplCode: true,
        dis_msgCont: true,
        dis_msgAttr: true,
        dis_useYn: true,
        dates: [],
        new_Type: [],
        groupNm:[],
        useYn:[],
        bussTypeCd:[],
        ctcpTypeCd:[],
        allCodeList : [],
        bussTypeCdT:[],
        ctcpTypeCdT:[],
        selGroupCd:[],

        gridDataHeaders: [
          { text: "No",              value: "ROW_NUMBER",          align: "center", width: "50px", sortable: true  },
          { text: "채널ID",          value: "PLUS_ID",       align: "center", width: "100px", sortable: true },
          { text: "사업지명",        value: "BUSS_NAME",     align: "center", width: "180px", sortable: true },
          { text: "TPL",           value: "MSG_TMPL_CODE",        align: "center", width: "80px", sortable: true },
          { text: "템플릿명",        value: "MSG_TMPL_NAME", align: "center", width: "120px", sortable: true },
          { text: "사용여부",         value: "USE_YN_NAME",        align: "center", width: "60px", sortable: true },


          { text: "건설사명",      value: "CTCP_NAME",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "사업지코드",    value: "BUSS_CODE",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "발송키값",     value: "SNDR_KEY",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "발송전화번호",     value: "SNDR_PHONE",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "템플릿유형",     value: "MSG_TYPE_CODE",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "메세지내용",     value: "MSG_CONT",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "알림톡유형",     value: "MSG_KIND_CODE",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "메세지상태유형",     value: "MSG_ATTR_CODE",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "사용여부",         value: "USE_YN",      width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "메세지상태유형",   value: "MSG_ATTR_NM", width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          
        ],

        gridBtnHeaders: [

          { text: "정렬순서",         value: "SORT_ORD",        align: "center", width: "80px", sortable: true },
          { text: "사업지명",        value: "BUSS_NAME",     align: "center", width: "120px", sortable: true },
          { text: "버튼유형",         value: "BTN_TYPE_NAME",        align: "center", width: "80px", sortable: true },
          { text: "버튼명",           value: "BTN_NAME",        align: "center", width: "80px", sortable: true },
          { text: "버튼링크유형",      value: "BTN_LINK_TYPE_NAME",        align: "center", width: "80px", sortable: true },
          { text: "버튼링크URL",      value: "BTN_LINK_URL",        align: "center", width: "80px", sortable: true },
          
          { text: "No",              value: "ROW_INDEX",          width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "사업지코드",       value: "BUSS_CODE",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "TPL",            value: "MSG_TMPL_CODE",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "버튼코드",         value: "CODE",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "버튼유형코드",      value: "BTN_TYPE",       width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "버튼링크유형코드",      value: "BTN_LINK_TYPE",         width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },

        ],

        gridDataText: [],
        gridBtnList: [],
        clickedData: [],
        btnItem: [],
        selectedItem: {},
        selectedBtn: {},
        page: 1,
        pageCount: 0,
        itemsPerPage: 30,
        totalVisible: 10,

        //v-model
        ROW_NUMBER      :'',
        ROW_INDEX       :'',
        T_CTCP_CODE		  :'',	
        T_BUSS_CODE     :'',
        T_MSG_TMPL_NAME :'',
        T_MSG_TMPL_CODE :'',
        T_PLUS_ID       :'',
        T_SNDR_PHONE    :'',
        T_SENDER_KEY    :'',
        T_SMS_SENDER    :'',
        T_MSG_KIND_CODE :'',
        T_MSG_TYPE_CODE :'',
        T_SNDR_KEY      :'',  
        T_MSG_CONT      :'',
        T_ADTM_CONT     :'',
        T_ADD_CONT     :'',
        T_USE_YN       :'',
        T_EPHS_TYPE_CD  :'',
        T_EPHS_TITLE  :'',
        T_EPHS_ITEM_HEAD  :'',
        T_EPHS_ITEM_TITLE :'',
        T_EPHS_ITEM_DESCRIPTION  :'',
        T_EPHS_ITEM_NAME  :'',
        T_EPHS_ITEM_DESC  :'',

        S_TMPL_NAME:'',
        CTCP_CODE: '',
        BUSS_CODE: '',
        ENCRYPT_KEY: '',
        ORG_ENCRYPT_KEY: '',

        USER_AUTH_CHK: false,
      };

    },

    methods: {
      activeRow(row, value){
        this.selectedRow = row;
        row.select(Boolean(value));
        row.isSelected = Boolean(value);
      },
      
      disableRow(row, value){
        this.selectedRow = row;
        row.select(Boolean(value));
        row.isSelected = Boolean(value);
      },

      //**********************************
      //      알림톡관리 목록 조회 호출
      //**********************************
      async getListData(){

        if(!this.USER_AUTH_CHK) {
          if(this.mixin_isEmpty(this.CTCP_CODE)) {
            this.common_alert('건설사를 선택해주세요.', 'chk');
            return;  
          }

          if(this.mixin_isEmpty(this.BUSS_CODE)) {
            this.common_alert('사업지를 선택해주세요.', 'chk');
            return;  
          }    
        }

        let requestData = {
          headers: {},
          sendData:{},
        };

        //header 세팅
        requestData.headers["URL"] = "/api/hlw/campaign/buss-talk/manage/list";
        requestData.headers["SERVICE"] = "hlw.campaign.buss-talk.manage";
        requestData.headers["METHOD"] = "list";
        requestData.headers["ASYNC"] = false;

        //sendData 초기화
        requestData.sendData["CTCP_CODE"]       = this.CTCP_CODE;

        let businessPlaceSearch = this.BUSS_CODE == null ? '' : this.BUSS_CODE;
        requestData.sendData["BUSS_CODE"]       = businessPlaceSearch;

        requestData.sendData["MSG_TMPL_CODE"]   = this.S_TMPL_NAME;

        //선택된 데이터 초기화
        this.gridBtnList = [];

        //알림톡 버튼 초기화
        this.fnAlimBtnReset();

        let response = await this.common_postCall(requestData);
    
        if (!response.HEADER.ERROR_FLAG){
          this.result(response);
    
          this.cleanValidation();
          this.disabledActive();
        }else{
          this.common_alert("시스템에 오류가 발생하였습니다.", "error");
        }
      },

      //상세내용 활성화,비활성화
      disabledActive(item){
        if (item == "false"){
          this.dis_ctcpCode		= false;
          this.dis_BussCode		= false;
          this.dis_plusId			= false;
          this.dis_msgTmplCode	= false;
          this.dis_msgTmplName	= false;
          this.dis_sndrKey		= false;
          this.dis_sndrPhone		= false;
          this.dis_msgKindCode	= false;
          this.dis_msgTypeCode	= false;
          this.dis_msgCont		= false;
          this.dis_msgAttr		= false;
          this.dis_useYn			= false;
        }else if (item == "part"){
          this.dis_ctcpCode		= true;
          this.dis_BussCode		= true;
          this.dis_msgTmplCode= true;
          this.dis_plusId			= false;
          this.dis_msgTmplName	= false;
          this.dis_sndrKey		= false;
          this.dis_sndrPhone		= false;
          this.dis_msgKindCode	= false;
          this.dis_msgTypeCode	= false;
          this.dis_msgCont		= false;
          this.dis_msgAttr		= false;
          this.dis_useYn			= false;
        }
        else{
          this.dis_ctcpCode		= true;
          this.dis_BussCode		= true;
          this.dis_plusId			= true;
          this.dis_msgTmplCode	= true;
          this.dis_sndrKey		= true;
          this.dis_sndrPhone		= true;
          this.dis_msgKindCode	= true;
          this.dis_msgTypeCode	= true;
          this.dis_msgTmplName	= true;
          this.dis_msgCont		= true;
          this.dis_msgAttr		= true;
          this.dis_useYn			= true;
        }
      },

      result(res){
        let header= res.HEADER;
        this.totcount = header.TOT_COUNT;

        let data = res.DATA;
        this.gridDataText = data;

        let idx = 1;
        for(var i in this.gridDataText){
          this.gridDataText[i]["ROW_NUMBER"] = idx++;
        }
      },

      resultBtn(res){
        let headerBtn= res.HEADER;
        this.totcount = headerBtn.TOT_COUNT;
        let data = res.DATA;
        this.gridBtnList = data;

        let idx = 1;
        for(var i in this.gridBtnList){
          this.gridBtnList[i]["ROW_INDEX"] = idx++;
        }
      },

      clickActive(item){
        //클릭 시, 하이라이트
        return item.ROW_NUMBER == this.ROW_NUMBER ? 'active' : '';
      },

      clickBtnActive(item){
        //클릭 시, 하이라이트
        return item.ROW_INDEX == this.ROW_INDEX ? 'active' : '';
      },

      setGirdBtnSelected(response, row) {

        this.clickedData= [];

        if (response != null) {
          if (!row.isSelected) {
            this.activeRow(row, true);
          } else {
            this.activeRow(row, false);
          }
        }

        let data = response.DATA;
        this.clickedData = response;
      },

      //사업지 코드 가져오기
      async changeDetailBussType(ctcpTypeCd) {
        this.bussTypeCd = [];
        this.BUSS_CODE =  "";

        if (!this.mixin_isEmpty(ctcpTypeCd)) {
          this.bussTypeCd = await this.mixin_busscode_get(ctcpTypeCd);
        }
      },

      async changeDetailTxtBussType(ctcpTypeCd) {
        this.bussTypeCdT = [];
        this.T_BUSS_CODE =  "";

        if (!this.mixin_isEmpty(ctcpTypeCd)) {
          this.bussTypeCdT = await this.mixin_busscode_get(ctcpTypeCd);
        }
        
      },
      
      gridRowBtnClick(item, row) {
        if(!this.mixin_isEmpty(item)) {
          if (!row.isSelected){
            this.activeRow(row,true);
          } else {
            this.disableRow(row,false);
          }
        }

        this.selBtnGroupCd = [];
        this.selBtnGroup = {};

        this.ROW_INDEX = item.ROW_INDEX;
        this.selBtnGroupCd[0] = item;
        this.selBtnGroup = item;
      },

      async gridRowClick(item, row) {
        if(item != null){
          if (row.isSelected == false){
            this.activeRow(row,true);
          } else {
            this.disableRow(row,false);
          }
        }

        this.gridBtnList = [];
        this.selGroupCd = [];
        this.getListBtnData(item,this.btnItem);
        this.new_Type = "";
        this.ROW_NUMBER       = item.ROW_NUMBER;

        this.T_CTCP_CODE		  = item.CTCP_CODE;			
        this.T_BUSS_CODE      = item.BUSS_CODE;   
        this.T_PLUS_ID        = item.PLUS_ID;    
        this.T_MSG_TMPL_NAME  = item.MSG_TMPL_NAME;  
        this.T_MSG_TYPE_CODE  = item.MSG_TYPE_CODE;
        this.T_EPHS_TYPE_CD   = item.EPHS_TYPE_CD;
        this.T_SNDR_KEY       = item.SNDR_KEY;  
        this.T_SNDR_PHONE     = item.SNDR_PHONE;  
        this.T_MSG_KIND_CODE  = item.MSG_KIND_CODE;    
        this.T_MSG_TMPL_CODE  = item.MSG_TMPL_CODE; 
        this.T_MSG_CONT       = this.decode(item.MSG_CONT);  
        this.T_ADTM_CONT      = this.decode(item.ADTM_CONT);  
        this.T_ADD_CONT      = this.decode(item.ADD_CONT);  
        
        this.T_EPHS_TITLE     = item.EPHS_TITLE; 
        this.T_EPHS_ITEM_HEAD  = item.EPHS_ITEM_HEAD; 
        this.T_EPHS_ITEM_TITLE  = item.EPHS_ITEM_TITLE; 
        this.T_EPHS_ITEM_DESCRIPTION  = item.EPHS_ITEM_DESCRIPTION; 
        this.T_EPHS_ITEM_NAME = item.EPHS_ITEM_NAME; 
        this.T_EPHS_ITEM_DESC = item.EPHS_ITEM_DESC; 

        this.T_USE_YN        = item.USE_YN;       
        
        this.bussTypeCdT = await this.mixin_busscode_get(item.CTCP_CODE);
        this.selGroupCd[0] =item;
        this.disabledActive("part");

        //알림톡 버튼 초기화
        this.fnAlimBtnReset();
      },

      //알림톡 버튼 초기화
      fnAlimBtnReset() {
        this.selBtnGroupCd = [];
        this.selBtnGroup = {};
        this.ROW_INDEX = null;
      },
      
      //**********************************
      //      복사버튼 클릭시
      //**********************************
      async copyBtnForm(){

        if(this.selGroupCd.length == 0) {
          this.common_alert('선택된 항목이 존재하지 않습니다.', 'chk');
          return;
        }
        this.new_Type = "INSERT";
        this.T_MSG_TMPL_CODE = "";
        this.disabledActive("false");
      },

      //**********************************
      //      신규버튼 클릭시
      //**********************************
      async newBtnForm(){
        this.cleanValidation();
        this.disabledActive("false");
        this.new_Type="INSERT";
      },

      cleanValidation() {
        this.T_CTCP_CODE	    = "";	
        this.T_BUSS_CODE      = "";
        this.T_PLUS_ID        = "";
        this.T_MSG_TMPL_NAME  = "";
        this.T_MSG_TYPE_CODE  = "";
        this.T_SNDR_KEY       = "";
        this.T_SNDR_PHONE     = "";
        this.T_MSG_KIND_CODE  = "";  
        this.T_MSG_TMPL_CODE  = "";
        this.T_MSG_CONT       = "";
        this.T_ADTM_CONT      = "";
        this.T_ADD_CONT      = "";
        this.T_USE_YN         = "";
        this.T_EPHS_TYPE_CD   = "";

        this.T_EPHS_TITLE   = "";
        this.T_EPHS_ITEM_HEAD   = "";
        this.T_EPHS_ITEM_TITLE   = "";
        this.T_EPHS_ITEM_DESCRIPTION   = "";
        this.T_EPHS_ITEM_NAME   = "";
        this.T_EPHS_ITEM_DESC   = "";
      },

      //**********************************
      //      버튼 조회 호출
      //**********************************
      async getListBtnData(item, btnItem){

        let requestData = {
            headers: {},
            sendData:{},
        }

        //header 세팅
        requestData.headers["URL"] = "/api/hlw/campaign/buss-talk/btn-manage/list";
        requestData.headers["SERVICE"] = "hlw.campaign.buss-talk.btn-manage";
        requestData.headers["METHOD"] = "list";
        requestData.headers["ASYNC"] = false;

        //sendData 초기화
        requestData.sendData["BUSS_CODE"]       = item.BUSS_CODE;
        requestData.sendData["MSG_TMPL_CODE"]   = item.MSG_TMPL_CODE;

        let response = await this.common_postCall(requestData);

        if (response.HEADER.ERROR_FLAG) {
          this.common_alert("시스템에 오류가 발생하였습니다.", "error");
        }else{
          this.resultBtn(response);
        }
        return btnItem;
      },

      //***************************************
      //   모달 이벤트(등록, 수정, 삭제)
      //***************************************
      clickBtnForm(btn) {
    
        if(this.new_Type == "INSERT" && btn != "DELETE") {

          if(this.mixin_isEmpty(this.T_CTCP_CODE)){
            this.common_alert('건설사명을 입력해주세요.', 'chk');
            return;
          }

          if(this.mixin_isEmpty(this.T_BUSS_CODE)) {
            this.common_alert('사업지명을 입력해주세요.', 'chk');
            return;  
          }

          if(this.mixin_isEmpty(this.T_MSG_TMPL_CODE)) {
            this.common_alert('TPL 코드를 입력해주세요.', 'chk');
            return;  
          }

          if(this.mixin_isEmpty(this.T_MSG_KIND_CODE)) {
            this.common_alert('유형을 입력해주세요.', 'chk');
            return;  
          }

          if(this.mixin_isEmpty(this.T_MSG_TMPL_NAME)) {
            this.common_alert('템플릿명을 입력해주세요.', 'chk');
            return;  
          }

        }else{

          if(this.mixin_isEmpty(this.T_BUSS_CODE)) {
            this.common_alert('선택된 항목이 존재하지 않습니다.', 'chk');
            return;
          }
        }

        let delChk = "";
        if(this.new_Type == "INSERT") {
          delChk= "알림톡 등록 하시겠습니까?";
        }else{
          if (btn == "DELETE"){
            delChk= "알림톡을 삭제하시겠습니까?";
          } else if (btn == "UPDATE") {
            delChk= "알림톡을 수정 하시겠습니까?";
          }
        }

        this.common_confirm(delChk, this.clickBtnReg, btn, null, null, "chk");
      },

      async clickBtnReg(btn) {
        let requestData = {
            headers: {},
            sendData:{},
        }

        requestData.headers["URL"] = "/api/hlw/campaign/buss-talk/manage/regist";
        requestData.headers["SERVICE"] = "hlw.campaign.buss-talk.manage";
        requestData.headers["METHOD"] = "regist";
    
        if (this.new_Type != "INSERT"){
          if (btn == "DELETE"){
            requestData.headers["URL"] = "/api/hlw/campaign/buss-talk/manage/delete";
            requestData.headers["SERVICE"] = "hlw.campaign.buss-talk.manage";
            requestData.headers["METHOD"] = "delete";
          } else if (btn == "UPDATE") {
            requestData.headers["URL"] = "/api/hlw/campaign/buss-talk/manage/update";
            requestData.headers["SERVICE"] = "hlw.campaign.buss-talk.manage";
            requestData.headers["METHOD"] = "update";
          }
        }

        let insertList = [];
        this.new_Type = "";

        requestData.sendData["CNT"]               = 0;
        requestData.sendData["REGR_ID"]           = this.user_id;
        requestData.sendData["CTCP_CODE"]         = this.T_CTCP_CODE;
        requestData.sendData["BUSS_CODE"]         = this.T_BUSS_CODE;
        requestData.sendData["PLUS_ID"]           = this.T_PLUS_ID;
        requestData.sendData["MSG_TMPL_NAME"]     = this.T_MSG_TMPL_NAME;
        requestData.sendData["MSG_TYPE_CODE"]     = this.T_MSG_TYPE_CODE;
        requestData.sendData["SNDR_KEY"]          = this.T_SNDR_KEY;
        requestData.sendData["SNDR_PHONE"]        = this.T_SNDR_PHONE;
        requestData.sendData["MSG_KIND_CODE"]     = this.T_MSG_KIND_CODE;
        requestData.sendData["MSG_TMPL_CODE"]     = this.T_MSG_TMPL_CODE;
        requestData.sendData["MSG_CONT"]          = this.encode(this.T_MSG_CONT);
        requestData.sendData["ADTM_CONT"]         = this.encode(this.T_ADTM_CONT);
        requestData.sendData["ADD_CONT"]          = this.encode(this.T_ADD_CONT);

        requestData.sendData["EPHS_TITLE"]          = this.T_EPHS_TITLE
        requestData.sendData["EPHS_ITEM_HEAD"]      = this.T_EPHS_ITEM_HEAD
        requestData.sendData["EPHS_ITEM_TITLE"]     = this.T_EPHS_ITEM_TITLE
        requestData.sendData["EPHS_ITEM_DESCRIPTION"]     = this.T_EPHS_ITEM_DESCRIPTION
        requestData.sendData["EPHS_ITEM_NAME"]      = this.T_EPHS_ITEM_NAME
        requestData.sendData["EPHS_ITEM_DESC"]      = this.T_EPHS_ITEM_DESC
        
        requestData.sendData["EPHS_TYPE_CD"]     = this.T_EPHS_TYPE_CD;
        requestData.sendData["USE_YN"]            = this.T_USE_YN;

        let response = await this.common_postCall(requestData);
        
        if (response.HEADER.ERROR_FLAG) {
          if(response.HEADER.ERROR_MSG == "Y"){

            this.common_alert("이미 동일한 알림톡정보가 존재합니다.", "chk");
            this.new_Type = "INSERT";
          }else{
            this.common_alert("시스템에 오류가 발생하였습니다.", "error");
            this.getListData();
          }

        }else{
          this.common_alert("정상적으로 처리되었습니다.");
          this.getListData();
        }
      },

      //***************************************
      //   버튼 목록 삭제
      //***************************************
      async deleteBtn(){
        let deleteList = [];

        let obj = {
          BUSS_CODE: this.selBtnGroup.BUSS_CODE,
          MSG_TMPL_CODE:  this.selBtnGroup.MSG_TMPL_CODE,
          BTN_CODE:  this.selBtnGroup.CODE,
        };

        deleteList.push(obj);

        let requestData = {
          headers: {
            SERVICE: this.initHeaders.SERVICE,
            METHOD: this.initHeaders.METHOD,
            TYPE: this.initHeaders.TYPE,
          },
          sendData: {
            list : deleteList
          }
        };

        // header 세팅
        requestData.headers["URL"] = "/api/hlw/campaign/buss-talk/btn-popup/delete";
        requestData.headers["METHOD"] = "delete";
        requestData.headers["ASYNC"] = false;
        let response = await this.common_postCall(requestData);
        this.deleteCdCallBack(response);
      },

      deleteCdCallBack(response){
        if(this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
          this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error');
          return false;
        }
        this.common_alert(`정상처리 되었습니다.`);
        this.clickedData = [];
        let params = {
          BUSS_CODE: this.selBtnGroup.BUSS_CODE,
          MSG_TMPL_CODE: this.selBtnGroup.MSG_TMPL_CODE,
        }
        this.getListBtnData(params);
      },

      /**
      * XSS Encode
      */
      encode(strText) {

        if (strText === "" || strText == null) {
          return strText;
        }

        strText = strText.replaceAll("(", "&#40;");
        strText = strText.replaceAll(")", "&#41;");
        strText = strText.replaceAll("[", "&#91;");
        strText = strText.replaceAll("]", "&#93;");
        strText = strText.replaceAll("&", "&amp;");
        strText = strText.replaceAll('"', "&quot;");
        strText = strText.replaceAll("'", "&apos;");
        strText = strText.replaceAll("\r", "<br>");
        strText = strText.replaceAll("\n", "<p>");
        strText = strText.replaceAll("<", "&lt;");
        strText = strText.replaceAll(">", "&gt;");

        return strText;
      },

      /**
       * XSS Decode
       */
      decode(strText) {

        //값존재유무 체크
        if (strText === "" || strText == null) {
          return strText;
        }
        strText = strText.toString();

        //문자열 길이가 4이상일 경우만 처리
        if (strText.length <= 3) {
          return strText;
        }
        //순서바뀌면 안나옴
        strText = strText.replaceAll("&lt;", "<");
        strText = strText.replaceAll("&gt;", ">");
        strText = strText.replaceAll("&amp;", "&");
        strText = strText.replaceAll("&quot;", '"');
        strText = strText.replaceAll("&apos;", "'");
        strText = strText.replaceAll("<br>", "\r");
        strText = strText.replaceAll("<p>", "\n");
        strText = strText.replaceAll("&#91;", "[");
        strText = strText.replaceAll("&#93;", "]");
        strText = strText.replaceAll("&#40;", "(");
        strText = strText.replaceAll("&#41;", ")");

        return strText;
      },

      // 버튼목록:신규버튼
      registBtn(){
        if(this.selGroupCd.length == 0) {
          this.common_alert('선택된 항목이 존재하지 않습니다.', 'chk');
          return;
        }
        if(this.mixin_isEmpty(this.T_BUSS_CODE) || this.mixin_isEmpty(this.T_MSG_TMPL_CODE)) {
          this.common_alert('선택된 항목이 존재하지 않습니다.', 'chk');
          return;
        }
    
        this.CAS0200Param = {
          headerTitle: "알림톡버튼관리",
          data: this.selGroupCd[0],
          type: "NEW",
        };

        this.showDialog('CAS0200');
      },

      // 버튼목록:상세버튼
      setGirdBtnDblSelected(e, { item }) {
        this.getListBtnData(item,this.btnItem);

        this.CAS0200Param = {
          headerTitle: "알림톡버튼관리",
          data: item,
          bussNm: this.T_BUSS_NAME,
          msgTmplCode: this.T_MSG_TMPL_CODE,
          type: "UPDATE",
        };
        this.showDialog('CAS0200');
      },

      // 버튼목록:삭제버튼
      showDeleteBtnConfirm(){

        if(this.mixin_isEmpty(this.selBtnGroup.MSG_TMPL_CODE)) {
          this.common_alert('선택된 항목이 존재하지 않습니다.', 'chk');
          return;
        }

        this.common_confirm(`해당 알림톡 버튼 정보를 삭제하시겠습니까?`, this.deleteBtn, null, null, null, "chk");
      
      },

      showDialog(type) {
        this[`dialog${type}`] = true;
      },

      hideDialog(type) {
        this[`dialog${type}`] = false;
      },

      submitDialog(type) {
        this.gridDataText = [];
        this.selGroupCd = [];
        this.btnSearch();
        this[`dialog${type}`] = false;
      },

      resetDialog(type) {
        this.gridDataText = [];
        this.selGroupCd = [];
        this.btnSearch();
        this[`dialog${type}`] = false;
      },

      unlockDialog(type) {
        this.gridDataText = [];
        this.selGroupCd = [];
        this.btnSearch();
        this[`dialog${type}`] = false;
      },

    },

    async mounted() {
      this.cleanValidation();
      this.disabledActive();

      let pArr = ['HLW_USE_YN','HLW_CTCP_CD','HLW_EPHS_TYPE_CD','HLW_MSG_TYPE_CD','HLW_MSG_KIND_CD'];

      this.allCodeList = await this.mixin_common_code_get_all(pArr);
      
      if (this.user_role.userGroupCd == 'SYSTEMMANAGER') {
        this.USER_AUTH_CHK = true;
        this.ctcpTypeCd = await this.mixin_common_code_get(this.allCodeList,'HLW_CTCP_CD','전체');
        this.ctcpTypeCdT = await this.mixin_common_code_get(this.allCodeList,'HLW_CTCP_CD','전체');
      }else{
        this.USER_AUTH_CHK = false;
        let bussCtcpArr = await this.mixin_buss_ctcp_code_user_auto(this.user_role.userId);

        for (let index = 0; index < bussCtcpArr.length; index++) {
          const element = bussCtcpArr[index];

          let obj={
            CD:element.CTCP_CODE,
            CD_NM:element.CTCP_NAME
          };

          this.ctcpTypeCd.push(obj);
          this.ctcpTypeCdT.push(obj);

          obj={
            CD:element.BUSS_CODE,
            CD_NM:element.BUSS_NAME
          };

          this.bussTypeCd.push(obj);
        }

        if(!this.mixin_isEmpty(this.ctcpTypeCd)) {
          this.CTCP_CODE = this.ctcpTypeCd[0].CD;
        }

        this.ctcpTypeCd.unshift({CD:'', CD_NM:'선택'});
        this.bussTypeCd.unshift({CD:'', CD_NM:'선택'});
      }
    },

    computed: {
      initHeaders(){
        return {
          SERVICE : 'setting.agent.manage',
          METHOD : "",
          TYPE : 'BIZ_SERVICE',
        };
      },
    },
  };
</script>

<style>
  thead.v-data-table-header tr th {
    text-align-last: center;
  }
</style>
